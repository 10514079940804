<template>
  <div>
      <a-menu  mode="horizontal">
        <a-menu-item key="img" @click='toHome'> <img class='logo' src="../static/images/logo.png" alt=""></a-menu-item>
        <a-menu-item key="home"  @click='toHome'>首页</a-menu-item>
        <a-sub-menu>
          <span slot="title" class="submenu-title-wrapper" >国外广告</span >
            <a-menu-item v-for='(item,index) in menuList' :key="index" @click='toOther(item.url)'>
            {{item.title}}
            </a-menu-item>         
        </a-sub-menu>
        <a-menu-item key="domestic" @click='toBaidu'>国内广告</a-menu-item>
        <a-menu-item key="about"  @click='toAbout'>关于我们</a-menu-item>
        <a-menu-item key="college"><a href="https://ifunpi.com/forums/">躺赢文化学院</a> </a-menu-item>
        <a-menu-item key="case"><a href="https://ifunpi.com/blog/">案例展示</a></a-menu-item>
       <a-menu-item key="member"> <a href="https://ifunpi.com/vip/">VIP</a></a-menu-item>
    </a-menu>
  </div>
</template>

<script>
export default {
 name: 'TopMenu',
 components:{
   
 },
  data() {
    return {
     
      menuList:[{title:'Google',url:'/google'},{title:'Fackbook',url:'/facebook'}]
    };
  },
 methods: {
   toHome(){
     this.$router.push({ path:'/'})
   },
   toBaidu(){
     this.$router.push({ path:'/baidu'})
   },
   toAbout(){
     this.$router.push({ path:'/about'})
   },
   toOther(path){
     this.$router.push({ path:path})
   }
 }
 }
</script>

<style>
.logo{
  height: 54px;
}
li{
color: #2c3e50;
    font-weight: bold;  
}

</style>